@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

:root {
  --dark: #041727;
  --dimmer: #071f33;
  --dim: #093253;
  --bright: #126afc;
  --blue: #688FBC;
  --midblue: #9DBADC;
  --lightblue: #C3D4E8;
  --light: #fff;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  color: var(--light);
  background-color: #041727;
  background-color: var(--dark);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background-color: #0d0e17;
  background-color: #071f33;
  background-color: var(--dimmer);
}

body::-webkit-scrollbar-thumb {
  background-color: #9DBADC;
  background-color: var(--midblue);
}

main {
  background-color: #041727;
  background-color: var(--dark);
  margin-top: 4.5rem;
}

header {
  width: 100%;
  position: fixed;
  height: 4rem;
  padding: 1rem 2rem;
  background-color: var(--dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.logo img {
  margin-right: 0.5rem;
  height: 1.25rem;
}

.nav-right {
  display: flex;
  align-items: center;
}

.user-pfp {
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--dimmer);
  margin-right: 0.5rem;
}

.user-pfp img {
  height: 100%;
  border-radius: 50%;
}

.sign-out {
  color: var(--blue);
  font-size: 1rem;
  cursor: pointer;
}

.sign-out:hover {
  opacity: 0.5;
}

.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.auth h1 {
  display: none;
  margin-bottom: 2.5rem;
}

.auth h1 span {
  font-weight: 500;
}

.google-sign-in,
.apple-sign-in {
  margin: 0.5rem 1rem;
}

.google-sign-in button,
.apple-sign-in button {
  background-color: var(--light);
  width: 14.5rem;
  border: 0;
  border-radius: 0.5rem;
  outline: none;
  margin: 0.25rem 0;
  padding: 0.75rem 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-sign-in button:hover,
.apple-sign-in button:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.google-sign-in svg,
.apple-sign-in svg {
  font-size: 1.75rem;
  margin-right: 0.75rem;
}

@media screen and (min-width: 1024px) {
  .auth h1 {
    display: flex;
  }
}
.carousel {
  width: 25rem !important;
  overflow-x: hidden;
  z-index: 1;
}

.carousel-model {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-img {
  height: 15rem;
  margin: 1rem 0;
}

.carousel-model img {
  max-height: 17.5rem;
  max-width: 13.5rem;
}

.carousel-model h3 {
  margin: 1rem 0;
}

.carousel-model p {
  text-align: center;
  font-size: 0.85rem;
  width: 17.5rem;
  color: var(--blue);
}

.carousel-dots {
  margin: 2rem 0;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: var(--light) !important;
}


@media screen and (min-width: 1024px) {
  .carousel-img {
    height: 17.5rem;
    margin: 0.5rem 0;
  }

  .carousel-model img {
    max-height: 20rem;
    max-width: 15rem;
  }

  .carousel-model h3 {
    font-size: 1.375rem;
  }

  .carousel-model p {
    font-size: 1rem;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  pointer-events: visible;
  z-index: 10;
}

.modal-content {
  width: 95vw;
  max-width: 20rem;
  padding: 0.75rem;
  background-color: var(--dimmer);
  transition: all 0.3s ease-in-out;
  border-bottom: solid 0.25rem var(--dark);
  border-top: solid 0.25rem var(--dark);
  border-radius: 0.5rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.modal-header svg {
  width: 1rem;
  height: 1rem;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in;
  color: #ff2424;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0.5rem 0;
}

.modal-message {
  font-size: 0.9rem;
}

.modal-message span {
  font-size: 0.85rem;
  font-family: monospace;
  font-weight: bold;
  color: var(--midblue);
}

.modal-input {
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  color: var(--light);
  padding: 0.5rem 1rem;
  border: solid 1px var(--blue);
  background-color: var(--dark);
  width: 100%;
  text-overflow: ellipsis;
  margin-top: 0.75rem;
}

.modal-input:focus{
  outline: none;
}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-buttons button {
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  margin: 0.5rem;
  margin-top: 1.5rem;
  margin-right: 0;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: bold;
  color: var(--light);
}

.modal-no {
  background-color: var(--dimmer);
  border: solid 1px var(--light) !important;
}

.modal-yes {
  background-color: var(--bright);
}

.modal-buttons button:focus {
  outline: none;
}

.modal-buttons button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.modal-tip {
  font-size: 0.75rem;
}

@media screen and (min-width: 400px) {
  .modal-content {
    max-width: 25rem;
    padding: 1rem 1.5rem;
  }
}

.review-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-wrapper h1 {
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}

.review {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.review-block {
  /* z-index: 1; */
  width: 100%;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 1px var(--blue);
}

.days {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--dimmer);
  border: 1px solid var(--dim);
}

.day {
  z-index: 1;
  width: 20%;
  height: 100%;
  padding: 1rem 0.5rem;
  text-align: center;
  cursor: pointer;
}

@media screen and (min-width: 500px) {
  .review-block {
    width: 30rem;
  }

  .days {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .day {
    width: 100%;
  }
}

.day.active {
  background: linear-gradient(60.27deg, #126afc -76.24%, rgba(18, 106, 252, 0.12) 93.19%);
  border-radius: 0.5rem;
}

.course-wrapper {
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.course {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--dimmer);
  color: var(--blue);
}

.course-deets {
  width: 100%;
}

.course-name {
  color: var(--light)
}

.course-time,
.course-slot-loc {
  font-size: 0.9rem;
  margin: 0.25rem 0;
}

.course-slot-loc {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.course-loc svg {
  font-size: 0.75rem;
}

.course-edit {
  z-index: 1;
  height: 1rem;
  margin-left: 0.35rem;
}

.course-edit svg {
  font-size: 1rem;
}

.course-edit:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.review-add,
.review-confirm {
  z-index: 1;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  padding: 0.75rem 4.5rem;
  border: none;
  font-weight: bold;
  color: var(--light);
}

.review-add {
  background-color: var(--dark);
  border: solid 1px var(--light);
}

.review-confirm {
  background-color: var(--bright);
}

.review-add:focus,
.review-confirm:focus {
  outline: none;
}

.review-add:hover,
.review-confirm:hover {
  cursor: pointer;
  transform: scale(1.02);
}

@media screen and (min-width: 1024px) {
  .review-block {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .course {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }

  .course-edit {
    margin-left: 1.25rem;
  }
}
.fin-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fin-wrapper h1 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.fin {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 1;
}

.fin-hero {
  display: none;
}

.fin-interact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fin-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  padding: 0.75rem 4.5rem;
  border: none;
  font-weight: bold;
  color: var(--light);
  background-color: var(--bright);
}

.fin-edit:focus {
  outline: none;
}

.fin-links {
  width: 100%;
  max-width: 20rem;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 1px var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fin-links p {
  text-align: center;
  margin-bottom: 1rem;
}

/* .fin-links a {
  color: var(--dark);
  background-color: var(--light);
  width: 15rem;
  border: 0;
  border-radius: 0.5rem;
  outline: none;
  margin: 0.5rem 0;
  padding: 0.75rem 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
} */

.fin-links a {
  width: 15rem;
  margin: 0.75rem 0;
}

.fin-links a.android {
  width: 17rem;
  margin: 0;
}

.fin-links a img {
  width: 100%;
}

.fin-edit:hover,
.fin-links a:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.fin-edit svg {
  font-size: 1.75rem;
  margin-right: 0.75rem;
}

.fin-links a.disabled {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.3;
}

@media screen and (min-width: 1024px) {
  /* .fin-interact {
    flex-direction: column-reverse;
  } */

  .fin-wrapper h1 {
    margin-bottom: 2rem;
  }

  .fin-hero {
    display: block;
    width: 20%;
  }

  .fin-hero img {
    width: 100%;
  }

}

.loader-wrapper {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border-radius: 50%;
  border: calc(0.325 * 1rem) solid var(--dimmer);
  border-top: calc(0.325 * 1rem) solid var(--lightblue);
  width: calc(2.25 * 1rem);
  height: calc(2.25 * 1rem); /* Safari */
  animation: spin 1.75s linear infinite;
}

@media screen and (min-width: 768px) {
  .loader {
    border-radius: 50%;
    border: calc(0.35 * 1.5rem) solid var(--dimmer);
    border-top: calc(0.35 * 1.5rem) solid var(--lightblue);
    width: calc(2.5 * 1.5rem);
    height: calc(2.5 * 1.5rem);
  }
}

/* Safari */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.logged-in {
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.upload-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-wrapper h1 {
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload-divider {
  display: flex;
  align-items: center;
  margin: 1rem;
  margin-bottom: 2rem;
}

.upload-divider div {
  width: 100%;
  height: 1px;
  border: 1px white solid;
}

.upload-divider h2 {
  margin: 0.5rem 1rem;
}

.upload-text,
.upload-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.upload-text,
.upload-pic {
  z-index: 1;
  width: 100%;
}

.steps {
  margin: 2rem 1rem;
  list-style-type: decimal;
}

.steps li {
  margin: 0.5rem 0;
}

.steps li span {
  font-family: Monospace;
  font-weight: bold;
  color: var(--midblue);
}

#input-text,
.input-file-label,
.upload button {
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 0.5rem;
  color: var(--light);
  padding: 0.75rem 1rem;
  padding-left: 1rem;
}

#input-text {
  border: solid 1px var(--blue);
  background-color: var(--dark);
  width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 0.75rem;
}

.add-file {
  /* margin: 0 auto; */
  display: flex;
  align-items: center;
  /* flex-direction: column; */
}

#input-file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.input-file-label {
  margin-right: 1.5rem;
  background-color: var(--dark);
  border: solid 1px var(--blue);
  font-weight: bold;
}

.file-details {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload button {
  background-color: var(--bright);
  border: none;
  font-weight: bold;
}

#input-text:focus,
.upload button:focus {
  outline: none;
}

.input-file-label:hover,
.upload button:hover {
  cursor: pointer;
  transform: scale(1.02);
}


@media screen and (min-width: 1024px) {
  .logged-in {
    padding: 2rem;
  }

  .upload {
    flex-direction: row;
  }

  .upload-divider {
    flex-direction: column;
    margin: 1rem;
  }

  .upload-divider div {
    width: 1px;
    height: 100%;
    border: 1px white solid;
  }
}

.ellipse {
  position: fixed;
  overflow: hidden;
  opacity: 0.5;
}

.ellipse img {
  width: 17.5rem;
  overflow: hidden;
}

.ellipse-tr {
  top: 3rem;
  right: 0;
}

.ellipse-bl {
  bottom: -0.5rem;
  left: 0;
}

.landing {
  overflow-x: hidden;
  margin-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

@media screen and (min-width: 1025px) {
  .ellipse img {
    width: 35vw;
  }

  .ellipse-tr {
    top: 4rem;
  }

  .ellipse-bl {
    left: -10rem;
  }

  .landing {
    flex-direction: row;
  }
}
